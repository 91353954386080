export default {
  ApplicationNAME: 'Tree-X Affiliate',
  ApplicationAPI: 'https://api.treex-aff-uz.online/api/v1',
  Langs: ['ru', 'en'],
  RecaptchaV3: {
    token: '6Le9DQUoAAAAAC-kTrdU6zlFPZa56JU3xj21UAwM',
    action: 'affiliate'
  },
  RecaptchaV2: {
    token: '6Le9DQUoAAAAAC7yWYzCoxVn_Ftx2do_sYYfs0nH'
  },
  Timezone: 'Europe/Moscow'
}
